<template>
  <b-card>
    <div class="mb-2 flex justify-between">
      <h4 class="text-2xl text-black ">
        <strong>Broadcast</strong>
      </h4>
      <div class="rounded-lg px-1 flex items-center bg-[#F8F8F8]">
        <img
          width="30"
          src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komchat.svg"
          alt="logo-komchat"
        >
        <div class="text-lg">
          <strong class="text-black flex items">Komchat</strong>
        </div>
      </div>
    </div>
    <div class="mt-3 mb-2">
      <tab
        :tab-active="tabActive"
        @handleTab="handleTab"
      />
    </div>
    <div v-show="tabActive === 'brand'">
      <ForBrandsBroadcast />
    </div>
    <div v-show="tabActive === 'lite'">
      <LiteBroadcast />
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import tab from '../opsional/wa-notif/komchat/tab.vue'
import ForBrandsBroadcast from './Broadcast/components/ForBrandsBroadcast.vue'
import LiteBroadcast from './Broadcast/components/LiteBroadcast.vue'

export default {
  components: {
    BCard,
    tab,
    ForBrandsBroadcast,
    LiteBroadcast,
  },
  data() {
    return {
      tabActive: this.$route.params.type || 'lite',
      whatsAppList: this.$store.state.komchat.whatsAppList,
    }
  },
  methods: {
    handleTab(value) {
      this.tabActive = value
    },
  },
}

</script>
